import React, { useEffect, useRef } from 'react';
import { useHistory, useLocation, useParams } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import {
    ppListCountSelector, ppListGetActivePageSelector,
    ppListListSelector,
    ppListQueryStringSelector, ppListRegionIdBySlugSelector
} from 'MODULES/reselect/selectors/pp_list';
import omit from 'lodash/omit';
import map from 'lodash/map';
import keyBy from 'lodash/keyBy';
import size from 'lodash/size';
import PpSingleListItem from 'CONTAINERS/pp_list_single_item';
import classNames from 'classnames';
import Paging from 'COMPONENTS/paging/paging';
import EmptyPPList from 'COMPONENTS/empty/emptyPPlist';
import {
    CLASSIFIEDS_COUNT_MOBILE_INFINITY,
    CLASSIFIEDS_PER_PAGE,
    CLASSIFIEDS_PER_PAGE_SMALL
} from 'CONFIG';
import qs from 'qs';
import { getClassifiedViewType } from 'MODULES/reselect/selectors/ui';
import { LOT_COUNT_IN_TWO_ROWS } from 'CONST';
import { pickFilterValues } from 'MODULES/filters';
import { userDataReselect } from 'MODULES/reselect/messaging';
import { isMobile } from 'react-device-detect';
import SubscribeBlock from 'COMPONENTS/masonry/subscribe_block';
import { checkNested } from 'MODULES/checkNested';
import { getParamsFromSearch } from 'MODULES/getParamsFromSearch';
import { PP_TYPE } from 'MODULES/PP_TYPE';
import { getFilters } from 'ACTIONS/filters/getFilters';
import { setListFilters } from 'ACTIONS/pp_list/setListFilters';
import { getPpList } from 'ACTIONS/pp_list/getPpList';
import { CLASSIFIEDS_VIEW } from 'MODULES/CLASSIFIEDS_VIEW';
import SkeletonListLoader from 'COMPONENTS/skeleton_list_loader/skeleton_list_loader';

const PpList = ({ unseenFilterLots, openOnNewTab, i18n, ppType, url, row, categoryId, queryParam, initialFilter, match, fetchInfinite, showSubscribe, multipleCategoryString, disableInitialFetch, filterRegions }) => {
    const location = useLocation();
    const history = useHistory();
    const { region } = useParams();
    const regionId = useSelector(state => ppListRegionIdBySlugSelector(state, region));
    const lang = checkNested(match, ['params', 'lang']);
    let initialSearch;

    if (initialFilter && !location.search) {
        const mergedValues = omit(initialFilter, ['sort', regionId ? 'region' : '']);
        const queryParams = pickFilterValues(mergedValues);
        initialSearch = qs.stringify(queryParams, { addQueryPrefix: true });
    }

    const userData = useSelector(userDataReselect);
    const queryString = useSelector(state => ppListQueryStringSelector(state, ppType, location.pathname, initialSearch || location.search, queryParam, regionId));
    const lotList = useSelector(state => ppListListSelector(state, ppType, location.pathname, initialSearch || location.search, queryParam, regionId));
    const viewType = useSelector(getClassifiedViewType) || CLASSIFIEDS_VIEW.grid;
    const ppCount = useSelector(state => ppListCountSelector(state, ppType, location.pathname, initialSearch || location.search, queryParam, regionId));
    const activePage = ppListGetActivePageSelector(initialSearch || location.search);
    const dispatch = useDispatch();
    const pageCount = Math.ceil(ppCount / CLASSIFIEDS_PER_PAGE);
    const scrollToRef = useRef();
    const queryStringRef = useRef(queryString);
    const activePageRef = useRef(activePage);
    const switchPage = (page) => {
        const oldparams = getParamsFromSearch(initialSearch || window.location.search);
        const queryString = qs.stringify({ ...oldparams, page });
        let modal = '';
        if (oldparams.modalOpened) {
            modal = `modalOpened=${oldparams.modalOpened}`;
        }
        history.push({ search: queryString ? `${queryString}&${modal}` : modal });
        scrollToRef.current.scrollIntoView();
    };


    useEffect(() => {
        const filters = { ...getParamsFromSearch(initialSearch || location.search) };

        dispatch(setListFilters(filters, location.pathname, ppType));
        if (((isMobile && !queryString) || fetchInfinite) && !disableInitialFetch) {
            dispatch(getPpList(url, queryString, window.location.pathname, ppType));
        }
    }, [fetchInfinite, isMobile]);

    useEffect(() => {
        if (queryString || categoryId) {
            queryStringRef.current = queryString;
            dispatch(getPpList(url, queryString, location.pathname, ppType));
        }
    }, [categoryId]);

    useEffect(() => {
        if (activePage !== activePageRef.current && activePage !== 1) {
            window.scrollTo({
                top: 0,
                left: 0,
                behavior: 'smooth'
            });
        }
    }, [activePage]);

    useEffect(() => {
        if (queryString !== queryStringRef.current) {
            queryStringRef.current = queryString;
            dispatch(getPpList(url, queryString, location.pathname, ppType));
        }
    }, [queryString, url, location]);

    useEffect(() => {
        if (!queryString.includes('coordinate') && queryString && queryString !== queryStringRef.current) {
            queryStringRef.current = queryString;
            dispatch(getFilters(categoryId, false, false, false, queryString));
        }
    }, [queryString]);

    useEffect(() => {
        if (!regionId && region) {
            return false;
        }
        const filters = { ...getParamsFromSearch(initialSearch || location.search) };
        dispatch(setListFilters(filters, location.pathname, ppType));
        if (!isMobile && !disableInitialFetch && !queryString) {
            dispatch(getPpList(url, queryString, window.location.pathname, ppType));
        }
    }, [categoryId, region, lang]);


    useEffect(() => {
        if (regionId) {
            const filters = { ...getParamsFromSearch(initialSearch || location.search), region: regionId };
            dispatch(setListFilters(filters, location.pathname, ppType));
        } else {
            const filters = { ...getParamsFromSearch(initialSearch || location.search) };
            dispatch(setListFilters(filters, location.pathname, ppType));
        }
    }, [regionId]);

    if (!regionId && region) {
        return null;
    }

    if (ppCount === false || (region && !regionId)) {
        return (
            <div className="row">
                <SkeletonListLoader count={isMobile ? CLASSIFIEDS_COUNT_MOBILE_INFINITY : CLASSIFIEDS_PER_PAGE_SMALL} />
            </div>
        );
    }
    if (ppCount === 0) {
        return [
            showSubscribe && <SubscribeBlock key="subscribe" i18n={i18n} categoryId={categoryId} multipleCategoryString={multipleCategoryString} filterRegions={filterRegions} />,
            <EmptyPPList key="empty" i18n={i18n} />
        ];
    }

    return (
        <div
            className={classNames('classifieds-list', {
                'my-filters': ppType === PP_TYPE.filtersCategory,
                'list-view': viewType === CLASSIFIEDS_VIEW.list,
                'masonry-view': viewType === CLASSIFIEDS_VIEW.grid || viewType === CLASSIFIEDS_VIEW.clothes,
                row })}
            ref={scrollToRef}
            style={{ scrollMarginTop: 120 }}
        >
            {(ppCount > LOT_COUNT_IN_TWO_ROWS.desktop || isMobile && ppCount > LOT_COUNT_IN_TWO_ROWS.mobile) && showSubscribe && !isMobile && (
                <SubscribeBlock i18n={i18n} categoryId={categoryId} multipleCategoryString={multipleCategoryString} filterRegions={filterRegions} />
            )}
            {unseenFilterLots && map(unseenFilterLots.lots, lot => [
                Number(lot.id) === Number(checkNested(unseenFilterLots, 'lots[0].id')) && (
                    <div key="filter_subscriptions_unseen" className="legend w-100">
                        {i18n.__('Filter subscriptions unseen classifieds')}
                        <span className="gray-text ps-1 fw-100">
                            (
                            {size(unseenFilterLots.lots)}
                            )
                        </span>
                    </div>
                ),
                <PpSingleListItem
                    unseen
                    pageCount={pageCount}
                    lastPageItem={activePage === pageCount}
                    url={url}
                    pathname={location.pathname}
                    search={initialSearch || location.search}
                    regionId={regionId}
                    ppType={ppType}
                    queryParam={queryParam}
                    queryString={queryString}
                    openOnNewTab={openOnNewTab}
                    i18n={i18n}
                    lot={lot.id}
                    key={lot.id}
                    userData={userData}
                />,
                unseenFilterLots && unseenFilterLots.lots && (ppCount - size(unseenFilterLots.lots) > 0) && lot.id === Number(checkNested(unseenFilterLots.lots, [size(unseenFilterLots.lots) - 1, 'id'])) && (
                    <div key="all_filter_subscriptions_seen" className="legend w-100">
                        {i18n.__('All classifieds matching your filter parameters')}
                        <span className="gray-text ps-1 fw-100">
                            (
                            {ppCount - size(unseenFilterLots.lots)}
                            )
                        </span>
                    </div>
                )
            ])}
            {map(lotList, (lot, index) => [
                !checkNested(keyBy(checkNested(unseenFilterLots, 'lots'), 'id'), lot) && <PpSingleListItem pageCount={pageCount} lastPageItem={activePage === pageCount} url={url} pathname={location.pathname} search={initialSearch || location.search} regionId={regionId} ppType={ppType} queryParam={queryParam} queryString={queryString} openOnNewTab={openOnNewTab} i18n={i18n} lot={lot} key={lot} userData={userData} />,
                isMobile && index === 5 && showSubscribe && <SubscribeBlock i18n={i18n} categoryId={categoryId} multipleCategoryString={multipleCategoryString} filterRegions={filterRegions} />
            ])}

            {showSubscribe && <SubscribeBlock i18n={i18n} categoryId={categoryId} multipleCategoryString={multipleCategoryString} filterRegions={filterRegions} />}
            <Paging i18n={i18n} onPageChange={switchPage} totalCount={ppCount} pageCount={pageCount} activePage={activePage} location={location} />
        </div>
    );
};


export default React.memo(PpList);
