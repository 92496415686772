import { apiAction } from 'ACTIONS/api';
import { API_URLS } from 'CONFIG/API_URLS';
import { MESSAGING_API } from 'CONFIG';
import { checkNested } from 'MODULES/checkNested';
import { UPDATE_LOT_THREADS } from 'ACTIONS';
import { apiPrefix } from 'ACTIONS/messaging/index';
import reverse from 'lodash/reverse';

export const getAllThreadsForItem = (id, owner, order = 'DESC', fromTime = {}, timestamp) => (dispatch) => {
    dispatch(
        apiAction(
            {
                url: API_URLS.THREADS_BY_ID(id),
                auth: true,
                host: MESSAGING_API,
                data: { ...fromTime, order, pointer_direction: order },
                apiPrefix,
                onSuccess: data => () => {
                    const payload = checkNested(data, 'content');
                    if (payload) {
                        if (payload.data && payload.data.length > 0) {
                            reverse(payload.data).forEach((thread) => {
                                dispatch({
                                    type: UPDATE_LOT_THREADS,
                                    owner,
                                    payload: thread,
                                    timestamp
                                });
                            });
                        }
                    }
                },
                label: UPDATE_LOT_THREADS
            }
        )
    );
};
