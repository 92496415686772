import React, { useMemo } from 'react';
import size from 'lodash/size';
import values from 'lodash/values';
import classNames from 'classnames';
import { CURRENCY } from 'CONFIG';
import { DEFAULT_PRICE_TYPE, LOT_ACTION_TYPE } from 'CONST';
import OldPrice from 'COMPONENTS/classified/oldPrice';
import { checkNested } from 'MODULES/checkNested';
import { getActionClassName } from 'MODULES/getActionClassName';

const PriceFormatter = React.memo(({
    hideIcons,
    action,
    defaultReturnValue,
    prices = [],
    selectedPriceType,
    useWrapper,
    showFirst,
    hideCurrency,
    showFull,
    i18n,
    hideVat,
    isCreate
}) => {
    const actionId = checkNested(action, 'id');
    const priceValueClass = useMemo(() => classNames('pp-price-value', {
        'is-single': values(prices).length === 1,
        'mt-4 mt-lg-0': actionId !== LOT_ACTION_TYPE.sell.id && size(prices) > 0
    }), [prices, actionId]);

    const formatPriceValue = (price) => (price.discountedValue
        ? price.discountedValue.replace(/\B(?=(\d{3})+(?!\d))/g, '  ').replace('.00', '')
        : price.value.replace(/\B(?=(\d{3})+(?!\d))/g, '  ').replace('.00', ''));

    const appendCurrencyAndPriceType = (price, value) => {
        if (!hideCurrency) {
            value.push(CURRENCY);
        }

        if (price.priceType && price.priceType.id !== DEFAULT_PRICE_TYPE) {
            const longPrice = checkNested(price.priceType, 'name', '', true);
            const shortPrice = checkNested(price.priceType, 'short', '', true);
            if (longPrice || shortPrice) {
                value.push(
                    <span key={longPrice.toLowerCase()}>
                        {showFull ? longPrice.toLowerCase() : shortPrice.toLowerCase()}
                    </span>
                );
            }
        }

        return value;
    };

    const renderedPrices = useMemo(() => {
        if (prices.length > 0 && prices[0]) {
            return prices.map((price, index) => {
                if (showFirst && index > 0) return null; // Skip rendering if `showFirst` and not the first price

                if (!price.value || (selectedPriceType && selectedPriceType !== price.priceType.id)) {
                    return defaultReturnValue;
                }

                let value = [formatPriceValue(price)];
                value = appendCurrencyAndPriceType(price, value);

                // Handle for non-first prices (like rent value)
                if (index > 0) {
                    return (
                        <span className="pp-rent-value" key={value.join()}>
                            {value}
                        </span>
                    );
                }

                if (useWrapper) {
                    return (
                        <div className="grid-row-14" key={value.join()}>
                            <h2 className={priceValueClass}>
                                {hideIcons && actionId && <i className={getActionClassName(actionId)} />}
                                {actionId !== LOT_ACTION_TYPE.sell.id && size(prices) > 0 && (
                                    <span className="type">{checkNested(action, 'name')}</span>
                                )}
                                <div className="text-nowrap">{value}</div>
                                {size(prices) > 0 && (
                                    <div className="ms-auto vat-included">
                                        {!hideVat && <span className="me-1 fs-1rem">%</span>}
                                        {(isCreate && hideVat) ? <span>{i18n.__('VAT not included')}</span> : <span>{i18n.__('VAT included')}</span>}
                                    </div>
                                )}
                            </h2>
                        </div>
                    );
                }

                if (price.priceType) {
                    value.push(
                        <OldPrice
                            key="lastPriceInHistory"
                            price={price}
                            prices={prices}
                            priceTypeId={price.priceType.id}
                            discountedValue={checkNested(prices, '[0].discountedValue')}
                        />
                    );
                }

                return value;
            });
        }
        return null;
    }, [prices, showFirst, selectedPriceType, hideCurrency, showFull, useWrapper, defaultReturnValue, actionId, priceValueClass, i18n, hideVat]);

    if (!renderedPrices) {
        return (
            <div className="action-block">
                {actionId && !hideIcons && (
                    <h6>
                        <i className={getActionClassName(actionId)} />
                        {action && action.name}
                    </h6>
                )}
            </div>
        );
    }

    return renderedPrices;
});

export default PriceFormatter;
