import size from 'lodash/size';

export const sortCategoriesByHighlight = (categoryLevelList) => {
    const highlighted = categoryLevelList.filter(category => category.highlighted);
    const noHighlighted = categoryLevelList.filter(category => !category.highlighted);
    const additional = [];
    if (size(highlighted) > 0) {
        additional.push({ id: 'disabled', disabled: true });
    }

    return [...highlighted, ...additional, ...noHighlighted];
};
