import React from 'react';
import { useSelector } from 'react-redux';
import { ppListPpDataSelector } from 'MODULES/reselect/selectors/pp_list';
import PpMasonryBlock from 'COMPONENTS/pp_block';
import { getSelectedLanguage } from 'MODULES/reselect/selectors/general';
import { getClassifiedViewType } from 'MODULES/reselect/selectors/ui';
import { getUserCompanyId } from 'MODULES/reselect/selectors/user';
import { useRouteMatch } from 'react-router';
import { checkNested } from 'MODULES/checkNested';
import { CLASSIFIEDS_VIEW } from 'MODULES/CLASSIFIEDS_VIEW';

const PpSingleListItem = ({ userData, unseen, pageCount, lastPageItem, queryString, url, ppType, lot, i18n, openOnNewTab, queryParam, pathname, search, regionId, hideFooter }) => {
    const pp = useSelector(state => ppListPpDataSelector(state, lot));
    const lang = useSelector(getSelectedLanguage);
    const viewType = useSelector(getClassifiedViewType) || CLASSIFIEDS_VIEW.grid;
    const selectedLots = useSelector(state => state.ui.mSelect);
    const userCompanyId = useSelector(getUserCompanyId);
    const match = useRouteMatch();
    if (!pp.id) {
        return null;
    }
    return (
        <PpMasonryBlock
            hash={checkNested(pp, 'hash', match.params.hash)}
            unseen={unseen}
            pageCount={pageCount}
            lastPageItem={lastPageItem}
            queryString={queryString}
            selected={selectedLots[pp.id]}
            pathname={pathname}
            search={search}
            regionId={regionId}
            ppType={ppType}
            target={openOnNewTab ? '_blank' : ''}
            key={lot}
            viewType={viewType}
            pp={pp}
            ppCompanyId={checkNested(pp, 'company.id')}
            i18n={i18n}
            lang={lang}
            queryParam={queryParam}
            url={url}
            companyId={userCompanyId}
            categoryView
            userData={userData}
            hideFooter={hideFooter}
        />
    );
};


export default React.memo(PpSingleListItem);
