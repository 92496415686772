import React, { Suspense, useState } from 'react';
import {
    isCategoryTextExcludedInMasonry
} from 'MODULES/handlers';
import classNames from 'classnames';
import LotLink from 'COMPONENTS/masonry/lot_link';
import { ADD_BOOKMARK } from 'ACTIONS';
import FormattedPrice from 'COMPONENTS/classified/price_formatter';
import FilterIcons from 'COMPONENTS/masonry/lot_filters_icons';
import BookmarkButton from 'COMPONENTS/lot/bookmark_button/bookmark_button';
import ImageThumbGallery from 'COMPONENTS/masonry/lot_gallery';
import { useLocation, useRouteMatch } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { categoriesCurrentCategorySelector } from 'MODULES/reselect/selectors/categories';
import FormattedTitle from 'COMPONENTS/FormattedTitle';
import { categoryClass } from 'MODULES/categoryClass';
import { getParent } from 'MODULES/getParent';
import { drawFilterPreview } from 'MODULES/drawFilterPreview';
import { checkNested } from 'MODULES/checkNested';
import { isCategoryPicturePriority } from 'MODULES/isCategoryPicturePriority';
import { isSlugInRealEstateCategory } from 'MODULES/isSlugInRealEstateCategory';
import { PP_URLS } from 'CONFIG/PP_URLS';
import { CATEGORIES } from 'MODULES/CATEGORIES';
import { catIcons } from 'MODULES/catIcons';
import { PP_TYPE } from 'MODULES/PP_TYPE';
import { MODALS } from 'MODULES/MODALS';
import { LOT_VISIBILITY } from 'MODULES/LOT_VISIBILITY';
import { toggleBookmark } from 'ACTIONS/bookmarks/toggleBookmark';
import { deletePp } from 'ACTIONS/categories/deletePp';
import { showModal } from 'ACTIONS/ui/showModal';
import { showModalWithData } from 'ACTIONS/ui/showModalWithData';
import { lazyRetry, loadComponent } from 'MODULES/lazyRetry';
import { CropperLoader } from 'COMMON/loader';
import { CLASSIFIEDS_VIEW } from 'MODULES/CLASSIFIEDS_VIEW';
import { getBoomarkedClasifiedsState } from 'MODULES/reselect/selectors/bookmarks';
import Storage from 'HOC/storage';


const MSelect = loadComponent(() => lazyRetry(() => import('COMPONENTS/masonry/lot_mselect')));

const LotMenuDropdown = loadComponent(() => lazyRetry(() => import('COMPONENTS/masonry/lot_menu_dropdown')));

const LotMenuHorizontal = loadComponent(() => lazyRetry(() => import('COMPONENTS/masonry/lot_menu_horizontal')));

const PPBlockListView = loadComponent(() => lazyRetry(() => import('COMPONENTS/pp_block_list_view')));


const getCatgoryIcon = (test, category) => {
    const catIcon = catIcons[category];
    return catIcon ? `pp-category ${catIcon}` : 'pp-landing pp-picture-o';
};

const PpMasonryBlock = ({ ...props }) => {
    const {
        pageCount,
        lastPageItem,
        userData,
        pp,
        dropup,
        ppType,
        buildingLots,
        filterFormValues,
        target,
        lang,
        autoMargin,
        onClick,
        selected,
        toggleBack,
        lotsPath,
        i18n,
        pagePath,
        image,
        isMobile,
        edit,
        refLastLotRef,
        unseen,
        hash,
        activePage,
        viewType,
        widthClass,
        swiper,
        queryParam,
        pathname,
        search,
        regionId,
        url,
        categoryView,
        queryString,
        hideFooter
    } = props;
    const [show, setShow] = useState(true);
    const dispatch = useDispatch();
    const onRemove = () => {};
    const removeItem = (id, type) => {
        const callback = () => {
            setShow(false);
            dispatch(showModal(MODALS.confirmation, false));
        };
        if (type === 'bookmark') {
            return dispatch(toggleBookmark(id, true, callback));
        }
        return dispatch(deletePp(id, ppType, callback));
    };
    const toggleRemoveBookmark = (fetchingBookmark = () => {}) => {
        const modalData = {
            title: i18n.__('Remove from favorites?'),
            icon: 'pp-trash',
            text: i18n.__('Attention, removing this classified data will not be restored'),
            okText: i18n.__('Remove'),
            isFetching: `${ADD_BOOKMARK}.${pp.id}`,
            cancelAction: fetchingBookmark,
            okAction: onSuccess => removeItem(pp, 'bookmark', () => {
                onSuccess();
                fetchingBookmark();
            })
        };
        dispatch(showModalWithData(modalData, MODALS.confirmation));
    };
    const showFooter = (ppType) => {
        if (ppType === PP_TYPE.myCompany) {
            return true;
        }
        if (ppType === PP_TYPE.my || ppType === PP_TYPE.bookmarks || unseen) {
            return Storage.get('login') === '1';
        }
        return false;
    };
    const showMSelect = (ppType) => {
        if (ppType === PP_TYPE.myCompany) {
            return true;
        }
        if (ppType === PP_TYPE.my) {
            return Storage.get('login') === '1';
        }
        return false;
    };
    let file;
    const filters = pp.adFilterValues;

    if (ppType === PP_TYPE.search) {
        file = (pp.fileCount) ? pp.thumbnail : null;
    } else {
        file = (pp.fileCount) ? pp.thumbnail : null;
    }

    if (!file && image) {
        file = image;
    }
    const classifiedsList = categoryView;
    const location = useLocation();
    const match = useRouteMatch();
    const currentCategory = useSelector(state => categoriesCurrentCategorySelector(state, { match }));
    const enableSwipe = swiper;
    // These values later can be used to style blocks per design.
    const isMasonryView = viewType === CLASSIFIEDS_VIEW.grid;
    const isListView = viewType === CLASSIFIEDS_VIEW.list;
    const isPicturePriority = isCategoryPicturePriority(pp.category);
    const isClothingCategory = pp.category.viewType === CLASSIFIEDS_VIEW.clothes;
    const userId = checkNested(userData, ['id']);
    const createDate = checkNested(userData, ['createDate']);
    const companyId = checkNested(userData, ['company', 'id'], props.companyId);
    const bookMarkedLots = useSelector(getBoomarkedClasifiedsState);
    const visitedLots = useSelector(state => state.ui.visitedLots);
    const isBookmarked = checkNested(pp, 'isBookmarked');
    const isBookmarkedPP = checkNested(bookMarkedLots, [pp.id], isBookmarked);

    const isMyItem = checkNested(pp, 'company.id', 'non') === companyId || checkNested(pp, 'owner.id', 'non') === userId;
    const selectedPriceType = checkNested(filterFormValues, ['priceType']);
    const isVisited = checkNested(visitedLots, pp.id) && ppType !== PP_TYPE.myCompany && ppType !== PP_TYPE.my && ppType !== PP_TYPE.myCompanyLotsXML && ppType !== PP_TYPE.bookmarksMy;

    const ppCategoryPath = `/${[lang]}/${pp.category.slug[lang]}`;
    const categoryParentId = checkNested(currentCategory, 'parents.0.id', 0);
    const isRealEstateCategory = isSlugInRealEstateCategory(checkNested(pp, 'category.slug'));
    const lotFirstLevelCategory = checkNested(getParent(pp.category, true), 1, getParent(pp.category, true)[0]);
    const isClosed = (checkNested(pp, ['adState', 'visibility']) === LOT_VISIBILITY.closed.id || checkNested(pp, ['adState', 'visibility']) === LOT_VISIBILITY.deleted.id) && !dropup;

    const wrapperClass = classNames(`classified pptype-${ppType}`, {
        'pp-disabled': isClosed,
        'masonry-block-width': isMasonryView,
        'override-width': widthClass,
        'm-auto': autoMargin,
        'example-hover-lots': ppType === PP_TYPE.my || ppType === PP_TYPE.myCompany || ppType === PP_TYPE.myCompanyLotsXML,
        [props.className]: props.className
    });
    const description = isMobile && (filters && filters.length > 2 || Number(categoryParentId) === CATEGORIES.realEstate);
    const categoryAddress = checkNested(pp.publicLocation, 'address');
    if (!show) {
        return null;
    }
    return [
        isMasonryView && (
            <div
                key={pp.id}
                className={wrapperClass}
                id={`classified-${pp.id}`}
                ref={refLastLotRef}
            >
                <div
                    style={{ zIndex: dropup ? '5' : 'initial' }}
                    className={classNames({
                        'pp-masonry masonry-classified': isMasonryView,
                        'pp-masonry-filters': unseen,
                        'pp-masonry-checked': selected,
                        'pp-list-view': isListView,
                        'highlighted is-bookmarked': isBookmarkedPP,
                        'pp-blocked': checkNested(pp.adState, 'visibility') === LOT_VISIBILITY.blocked.id,
                        'is-my-lot': isMyItem,
                        'priority-picture': isPicturePriority || isClothingCategory,
                        'has-footer': showFooter(ppType),
                        'real-estate': isRealEstateCategory
                    })}
                >
                    <LotLink
                        to={{
                            pathname: PP_URLS.PP(pp.id, pp.category.slug[lang]),
                            state: {
                                locationPath:  window.location.pathname,
                                searchParams: props.search,
                                pagePath,
                                ppType,
                                pp,
                                clickedToLot: true,
                                lotsPath,
                                lotDispatcher: decodeURIComponent(window.location.pathname),
                                currentCategory: currentCategory && currentCategory.id,
                                edit,
                                hash,
                                queryParam,
                                pathname,
                                search,
                                regionId,
                                listApiUrl: url,
                                goBackLink: location.pathname,
                                goBackSearch: location.search,
                                lastPageItem,
                                pageCount
                            }
                        }}
                        frontUrl={pp.frontUrl}
                        category={pp.category}
                        target={target}
                        isMap={ppType === PP_TYPE.map}
                        href={PP_URLS.PP(pp.id, pp.category.slug[lang])}
                    >
                        <div
                            className="masonry-body"
                        >
                            {checkNested(pp.discount, 'percent')
                                && (
                                    <div className="discount-price">
                                        {pp.discount.percent}
                                        %
                                    </div>
                                )}
                            {pp.fileCount
                                ? <ImageThumbGallery ppType={ppType} pp={pp} i18n={i18n} swipe={enableSwipe} isCategories={classifiedsList} isClosed={isClosed} />
                                : (
                                    <div className="masonry-image image-placeholder">
                                        <i
                                            className={categoryClass(checkNested(currentCategory, 'parents.1.id'), checkNested(currentCategory, 'level'))
                                                        || categoryClass(checkNested(currentCategory, 'parents.0.id'), checkNested(currentCategory, 'level'))
                                                        || categoryClass(checkNested(currentCategory, 'id'), checkNested(currentCategory, 'level'))
                                                        || getCatgoryIcon(pp.category)}
                                            aria-hidden="true"
                                        />
                                    </div>
                                )}

                            {!isPicturePriority && !isClothingCategory && (location.pathname !== ppCategoryPath || Number(categoryParentId) === CATEGORIES.realEstate) && (
                                <strong
                                    className={classNames('mx-2 d-block mb-1', {
                                        'single-line': checkNested(pp.publicLocation, 'street.name'),
                                        'd-flex': !checkNested(pp.publicLocation, 'street.name')
                                    })}
                                    data-test="classified-title"
                                >
                                    <div
                                        title={categoryAddress ? `${pp.categoryTitle}, ${checkNested(pp.publicLocation, 'address')}` : pp.categoryTitle}
                                        className={classNames('single-line', {
                                            'line-clamp-2': (pp.categoryTitle && pp.categoryTitle.length) + (categoryAddress && categoryAddress.length) > 25
                                        })}
                                        style={{ WebkitBoxOrient: 'vertical' }}
                                        data-nosnippet="true"
                                    >
                                        {categoryAddress
                                            ? (
                                                <>
                                                    {ppType === PP_TYPE.list && !location.pathname.includes('landing')
                                                        ? (
                                                            <div className="single-line" data-nosnippet="true">
                                                                {pp.regionTitle}
                                                                ,
                                                            </div>
                                                        )
                                                        : (
                                                            <div className="single-line" data-nosnippet="true">
                                                                {pp.categoryTitle}
                                                                ,
                                                            </div>
                                                        )}
                                                    <div className="single-line" data-nosnippet="true">{categoryAddress}</div>
                                                </>
                                            )
                                            : pp.categoryTitle}
                                    </div>
                                </strong>
                            )}
                            {!isPicturePriority && !isClothingCategory && (
                                <div className="mx-2">
                                    <div className="masonry-text" data-nosnippet="true">
                                        {(filters && filters.length > 0) ? <FilterIcons key={`filters_${pp.id}`} publicLocation={pp.publicLocation} allFilters={pp.adFilterValues} category={pp.category} filters={drawFilterPreview(pp.adFilterValues, i18n)} i18n={i18n} /> : null}
                                        {pp.title && !isCategoryTextExcludedInMasonry(lotFirstLevelCategory.id) && (
                                            <FormattedTitle title={pp.title} />
                                        )}
                                    </div>
                                </div>
                            )}
                        </div>
                        {isPicturePriority && (
                            <div className={classNames('masonry-title fw-500 pb-2 mx-2 mb-1', { 'masonry-title fw-500 pb-4 mx-2 mb-1': !pp.title })} data-nosnippet="true" title={pp.title}>
                                {pp.title}
                            </div>
                        )}
                        <div className="masonry-header">
                            <div className="d-flex justify-content-end w-100">
                                {isVisited && <i className="pp-landing pp-visited" title={i18n.__('Visited page')} />}
                                {/* {(pp.company && pp.company.trusted) ? */}
                                {/*    <i className="fa fa-thumbs-o-up pp-liked" /> : null} */}
                                {isMyItem && (
                                    <i
                                        title={i18n.__('This is Your classified')}
                                        className="pp-landing pp-my-lots py-1 ps-2"
                                    />
                                )}
                                {pp.prices && (
                                    <span
                                        className={classNames({
                                            'masonry-price': viewType === CLASSIFIEDS_VIEW.grid,
                                            'list-price': viewType === CLASSIFIEDS_VIEW.list
                                        })}
                                        id="lot-block-price"
                                    >
                                        <div className="d-flex">
                                            <div className="d-flex pb-2 pt-1 px-2 flex-wrap justify-content-end">
                                                {pp.prices[0] ? (
                                                    <FormattedPrice
                                                        action={pp.action}
                                                        prices={pp.prices}
                                                        selectedPriceType={selectedPriceType && parseInt(selectedPriceType, 10)}
                                                        showFirst
                                                    />
                                                ) : checkNested(pp.action, 'name')}
                                            </div>
                                            {ppType !== PP_TYPE.my && ppType !== PP_TYPE.myCompany && (
                                                <BookmarkButton
                                                    pp={pp}
                                                    ppType={ppType}
                                                    toggleRemoveBookmark={toggleRemoveBookmark}
                                                    i18n={i18n}
                                                    favoriteCount={pp.favoriteCount}
                                                    lotsPath={lotsPath}
                                                    activePage={activePage}
                                                />
                                            )}
                                        </div>
                                    </span>
                                )}
                            </div>
                        </div>
                    </LotLink>
                    {showMSelect(ppType) && (
                        <div className="hover-overlay">
                            <LotLink
                                to={{
                                    pathname: PP_URLS.PP(pp.id, pp.category.slug[lang]),
                                    state: {
                                        locationPath: window.location.pathname,
                                        pagePath,
                                        searchParams: props.search,
                                        ppType,
                                        pp,
                                        clickedToLot: true,
                                        lotsPath,
                                        lotDispatcher: decodeURIComponent(window.location.pathname),
                                        currentCategory: currentCategory && currentCategory.id,
                                        hash,
                                        queryParam,
                                        pathname,
                                        search,
                                        regionId,
                                        listApiUrl: url,
                                        goBackLink: location.pathname,
                                        goBackSearch: location.search,
                                        lastPageItem,
                                        pageCount
                                    }
                                }}
                                onClick={onClick}
                                target={target}
                                href={PP_URLS.PP(pp.id, pp.category.slug[lang])}
                            >
                                <button type="button" className="btn btn-secondary btn-transparent mx-auto">
                                    <i className="pp-ico pp-eye" />
                                    {' '}
                                    {i18n.__('View')}

                                </button>
                            </LotLink>
                        </div>
                    )}
                    {showFooter(ppType) && !hideFooter
                    && (
                        <div className="pp-footer">
                            {/* need good style for this */}
                            {/* {pp.endDate && i18n.__('Classified expires: %s', [Moment(pp.endDate, 'YYYY-MM-DD').format(FULL_DATE_DISPLAY_FORMAT)])} */}
                            {(showMSelect(ppType) || (ppType === PP_TYPE.filtersCategory && unseen))
                                && <Suspense fallback={CropperLoader()}><MSelect queryString={queryString} pathname={pathname} selected={selected} queryParam={queryParam} ppType={ppType} id={pp.id} /></Suspense>}
                            {ppType !== PP_TYPE.filtersCategory && (
                                <LotMenuHorizontal
                                    {...props}
                                    companyId={companyId}
                                    userId={userId}
                                    removeItem={removeItem}
                                    toggleRemoveBookmark={toggleRemoveBookmark}
                                />
                            )}
                            {ppType === PP_TYPE.filtersCategory && unseen && <span>{i18n.p__('filter_subscription', 'Unseen')}</span>}
                            {ppType !== PP_TYPE.filtersCategory && (
                                <div className="d-flex align-items-center ms-auto">
                                    <LotMenuDropdown
                                        {...props}
                                        userId={userId}
                                        createDate={createDate}
                                        removeItem={removeItem}
                                        toggleRemoveBookmark={toggleRemoveBookmark}
                                        isMyItem={isMyItem}
                                    />
                                </div>
                            )}
                        </div>
                    )}
                    {/* FYI Toggle BACK,  buildingLots is used in google map */}
                    {buildingLots > 1 && (
                        <a onClick={toggleBack} className="back-btn">
                            <i className="pp-ico pp-long-arrow-left" aria-hidden="true" />
                            <span>{i18n.__('Back')}</span>
                        </a>
                    )}
                </div>
            </div>
        ),
        isListView && (
            <PPBlockListView
                {...props}
                queryString={queryString}
                description={description}
                key="listView"
                show={show}
                search={search}
                selected={selected}
                showFooter={showFooter}
                showMSelect={showMSelect}
                hash={hash}
                onRemove={onRemove}
                onClick={onClick}
                isListView={isListView}
                isMasonryView={isMasonryView}
                regionId={regionId}
                isMyItem={isMyItem}
                isVisited={isVisited}
                currentCategory={currentCategory}
                pageCount={pageCount}
                pp={pp}
                ppType={ppType}
                hideFooter={hideFooter}
                lang={lang}
                pagePath={pagePath}
                pathname={pathname}
                lastPageItem={lastPageItem}
                location={location}
                lotsPath={lotsPath}
                url={url}
                wrapperClass={wrapperClass}
                target={target}
                queryParam={queryParam}
                unseen={unseen}
                dropup={dropup}
                i18n={i18n}
                classifiedsList={classifiedsList}
                swiper={swiper}
                isClosed={isClosed}
                ppCategoryPath={ppCategoryPath}
                categoryParentId={categoryParentId}
                filters={filters}
                viewType={viewType}
                selectedPriceType={selectedPriceType}
                toggleRemoveBookmark={toggleRemoveBookmark}
                activePage={activePage}
                buildingLots={buildingLots}
                companyId={companyId}
                userId={userId}
                removeItem={removeItem}
                getCatgoryIcon={getCatgoryIcon}

            />
        )

    ];
};

PpMasonryBlock.defaultProps = {
    target: '' // Target is by default empty
};

export default PpMasonryBlock;
