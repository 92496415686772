export const CATEGORIES = {
    spareParts: 7843,
    transport: 1,
    cars: 2,
    tires: 5,
    tyres: 2406,
    heavy: 2314,
    rims: 2428,
    moto: 2113,
    motorcycle: 2114,
    air: 2555,
    water: 2483,
    bicycle: 2540,
    tools: 11407,
    disabledTransport: 2561,
    transportation: 2568,
    navigation: 2586,
    accessories: 3727,
    transportOther: 2621,
    transportElectric: 11305,
    transportRental: 11311,
    carRepair: 3331,
    whereToBuy: 3827,
    other: 3807,
    barCafe: 7241,
    hotels: 7247,
    restaurants: 7253,
    fishingPlaces: 7681,
    bath: 7743,
    camps: 7745,
    hotelsHostels: 7749,
    guestHouses: 7755,
    countrysideTourism: 7757,
    charity: 7829,
    events: 3346,
    services: 2799,
    servicesBeauty: 3266,
    servicesConstruction: 2800,
    servicesCraftsman: 2967,
    servicesDelivery: 3231,
    finishingWorks: 2885,
    healthminister: 8099,
    servicesHousehold: 3277,
    servicesDesign: 3187,
    servicesElectronicsRepair: 3063,
    servicesIT: 3402,
    servicesProfessional: 3138,
    servicesTeachers: 3455,
    servicesVacancy: 11189,
    servicesVolunteer: 3468,
    servicesHouseholdWorks: 6073,
    servicesOther: 3469,
    animals: 3692,
    cats: 3694,
    dogs: 3693,
    rodents: 3695,
    ferrets: 3702,
    exotic: 3698,
    fish: 3697,
    birds: 3696,
    veterinary: 3699,
    petsCage: 11185,
    petsBed: 11183,
    petsFood: 3725,
    petsBowl: 11209,
    petsCollar: 8101,
    petsHygiene: 11211,
    petsPlayground: 11215,
    petsTerrarium: 11217,
    petsToilet: 11213,
    petsToys: 11207,
    petsOther: 3700,
    flats: 3811,
    realEstate: 3809,
    premises: 3821,
    realEstateOffices: 3823,
    realEstateLands: 3817,
    realEstateForest: 3819,
    realEstateFarmstead: 3815,
    realEstateApartments: 3811,
    realEstateGarage: 11415,
    realEstateSaunas: 11419,
    realEstateModularHomes: 11417,
    electronic: 3680,
    electronicsRepair: 8103,
    electronicsPhones: 3829,
    electronicsAppliances: 3901,
    electronicsComputers: 4027,
    electronicsAudioVideo: 4147,
    electronicsBatteries: 4263,
    electronicsTV: 4301,
    electronicsCameras: 4341,
    electronicsGPS: 4387,
    electronicsSecurityCamera: 6103,
    electronicsLamps: 11219,
    electronicsOther: 4395,
    construction: 4423,
    constructionWorks: 4681,
    constructionTools: 4815,
    constructionToolRent: 5033,
    constructionDesign: 5219,
    constructionHeating: 11111,
    constructionDelivery: 5231,
    constructionPlumbing: 4425,
    constructionMaterials: 4515,
    constructionOther: 5407,
    garden: 3731,
    gardening: 5297,
    gardeningGrass: 3733,
    gardeningSaw: 3761,
    gardeningWatering: 3763,
    gardeningSnowPlow: 3783,
    gardenForestMachinery: 3731,
    gardeningMechanicalBrooms: 3785,
    gardeningEngines: 3787,
    gardeningGenerators: 3789,
    gardeningClothing: 3797,
    gardeningAccessories: 3799,
    gardeningFurniture: 3801,
    gardeningForChildren: 3803,
    gardeningGreenhouse: 3755,
    gardeningOther: 3805,
    fruits: 3507,
    food: 3470,
    foodFruits: 11169,
    foodOther: 3677,
    foodVeggie: 3471,
    foodDrinks: 3643,
    foodMeat: 3524,
    foodSpices: 3617,
    foodGrain: 3573,
    foodBread: 3594,
    foodHoney: 3634,
    foodMilk: 3559,
    foodFrozen: 3610,
    foodAlcohol: 3654,
    foodRuralGoods: 11414,
    homes: 3813,
    homeStuff: 5409,
    homeFurniture: 5411,
    homeBeauty: 5585,
    homeJewelry: 5679,
    homeSouvenir: 5757,
    homeAntique: 5797,
    homePlant: 5859,
    homeLaf: 5913,
    homeOther: 5947,
    homeGardening: 5949,
    homeDetergents: 6007,
    homeFeast: 11497,
    clothes: 6105,
    clothesWomen: 6107,
    clothesMen: 6207,
    clothesKids: 6267,
    clothesShoes: 6277,
    clothesAccessories: 6357,
    clothesOveralls: 6433,
    clothesSeamstress: 6435,
    clothesOther: 6437,
    production: 6439,
    productionConveyor: 11147,
    productionManufacturing: 6441,
    productionFood: 6501,
    productionDistribution: 6527,
    productionPackaging: 6551,
    productionHousehold: 6575,
    productionFirewood: 6605,
    agriculture: 6621,
    agricultureHusbandry: 6623,
    agricultureAviculture: 6667,
    agricultureFish: 6691,
    agricultureWorks: 6729,
    agricultureFertilizer: 6731,
    agricultureSeeds: 6733,
    agricultureMachinery: 2778,
    agricultureOther: 6735,
    kids: 6737,
    kidsBag: 11149,
    kidsSchool: 6739,
    kidsClothes: 6753,
    kidsSwing: 6839,
    kidsPushchair: 6921,
    kidsFurniture: 6923,
    kidsSeats: 6955,
    kidsFood: 6987,
    kidsHobby: 7023,
    kidsOther: 7039,
    hobby: 7041,
    hobbyPastime: 7043,
    hobbyCollecting: 7161,
    hobbyConcerts: 7227,
    hobbyMusic: 7265,
    hobbyBooks: 7345,
    hobbySports: 7385,
    hobbyHunting: 7615,
    hobbyTourism: 7741,
    hobbyDating: 7799,
    hobbyOther: 7801,
    health: 3807,
    healthAccessories: 5677,
    healthBeauty: 5587,
    healthDancing: 5669,
    healthFitness: 5667,
    healthHairdresser: 5671,
    healthHealth: 5613,
    healthHealthcare: 5633,
    healthManicure: 5673,
    healthMassage: 5635,
    healthStylist: 5675,
    healthSauna: 11197,
    adultContent: 11271,
    adultContentClothes: 11277,
    adultContentGames: 11273,
    adultContentMasturbator: 11281,
    adultContentOffers: 11279,
    adultContentStrapon: 11285,
    adultContentToys: 11275,
    adultContentVibrator: 11283,
    adultContentPharmacy: 11405,
    christmas: 11287,
    trailer: 7825,
    winter_beater: 11291,
    christmasOffers: 11349
};
