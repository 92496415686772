import {
    ACTIVATE_THREAD,
    CLEAR_MESSAGING_STATE,
    FLUSH_ALL_LOTS,
    FLUSH_ALL_MESSAGES,
    FLUSH_ALL_THREADS,
    GET_ALL_MESSAGES,
    GET_MESSAGE_LOTS,
    GET_MESSAGES,
    GET_MESSAGING_LOT_LIST,
    GET_NEW_MESSAGES_COUNT,
    GET_PARTICIPANT_INFO,
    PATCHING_MESSAGE,
    RESET_USER_STATE,
    UPDATE_LOT_THREADS
} from 'ACTIONS';
import { getUnixTimeStamp } from 'MODULES/index';
import forEach from 'lodash/forEach';
import omitBy from 'lodash/omitBy';

import { checkNested } from 'MODULES/checkNested';

const INITIAL_STATE = {
    lots: {
        personal: {},
        company: {},
        amount: {}
    },
    participants: {},
    companyMessages: 0,
    privateMessages: 0,
    totalMessages: {}
};

export default function (state = INITIAL_STATE, action) {
    switch (action.type) {
        case GET_MESSAGING_LOT_LIST:
            let messagingObject = state.lots[action.owner] || {};
            forEach(action.payload, (d, key) => {
                messagingObject = omitBy(messagingObject, { itemId: d.itemId });
                messagingObject = { ...messagingObject, [key]: { ...checkNested(messagingObject, key, {}), ...d } };
            });
            return {
                ...state,
                lots: {
                    ...state.lots,
                    [action.owner]: {
                        ...state.lots[action.owner],
                        ...messagingObject

                    },
                    amount: {
                        ...state.lots.amount,
                        [action.owner]: action.amount
                    }
                }
            };
        case GET_MESSAGE_LOTS:
            if (action.payload.blocked) {
                return {
                    ...state,
                    lots: {
                        ...state.lots,
                        [action.owner]: {
                            ...state.lots[action.owner],
                            [action.id]: {
                                ...state.lots[action.owner][action.id],
                                lot: action.payload
                            }
                        }
                    }
                };
            }
            return {
                ...state,
                lots: {
                    ...state.lots,
                    [action.owner]: {
                        ...state.lots[action.owner],
                        [action.id]: {
                            ...state.lots[action.owner][action.id],
                            lot: action.payload
                        }
                    }
                }
            };
        case UPDATE_LOT_THREADS:
            const updatedAt = action.timestamp ? action.timestamp : getUnixTimeStamp(action.payload.updatedAt);
            return {
                ...state,
                lots: {
                    ...state.lots,
                    [action.owner]: {
                        ...state.lots[action.owner],
                        [updatedAt]: {
                            ...state.lots[action.owner][updatedAt],
                            uniqueHash: action.payload.uniqueHash,
                            threads: checkNested(state.lots[action.owner][updatedAt], 'threads') ? [
                                ...state.lots[action.owner][updatedAt].threads,
                                action.payload.uniqueHash
                            ] : [
                                action.payload.uniqueHash
                            ],
                            [action.payload.uniqueHash]: state.lots[action.owner][updatedAt] ? {
                                ...state.lots[action.owner][updatedAt][action.payload.uniqueHash],
                                ...action.payload,
                                updatedAt
                            } : {
                                ...action.payload,
                                updatedAt
                            }
                        }
                    }
                }
            };
        case GET_PARTICIPANT_INFO:
            return ({
                ...state,
                participants: {
                    ...state.participants,
                    [`${action.participantType}_${action.payload.id}`]: action.payload
                }
            });
        case ACTIVATE_THREAD:
            return {
                ...state,
                thread: action.payload
            };
        case GET_MESSAGES:
            return {
                ...state,
                lots: {
                    ...state.lots,
                    [action.owner]: {
                        ...state.lots[action.owner],
                        [action.id]: {
                            ...state.lots[action.owner][action.id],
                            [action.hash]: state.lots[action.owner][action.id] ? {
                                ...state.lots[action.owner][action.id][action.hash],
                                messages: {
                                    ...state.lots[action.owner][action.id][action.hash].messages,
                                    ...action.payload
                                },
                                lastNotOwnedMessage: action.lastNotOwnedMessage
                            } : {
                                messages: {
                                    ...action.payload,
                                    ...action.lastNotOwnedMessage
                                },
                                lastNotOwnedMessage: action.lastNotOwnedMessage
                            },
                            uniqueHash: action.hash
                        }
                    }
                }
            };
        case GET_NEW_MESSAGES_COUNT:
            return {
                ...state,
                unreadMessages: action.payload,
                companyMessages: action.company,
                privateMessages: action.user
            };
        case GET_ALL_MESSAGES:
            return ({
                ...state,
                totalMessages: action.payload
            });
        case PATCHING_MESSAGE:
            return {
                ...state,
                lots: {
                    ...state.lots,
                    [action.owner]: {
                        ...state.lots[action.owner],
                        [action.id]: {
                            ...state.lots[action.owner][action.id],
                            [action.hash]: {
                                ...state.lots[action.owner][action.id][action.hash],
                                ...state.lots[action.owner][action.id][action.hash].messages,
                                newMessages: 0,
                                messages: {
                                    ...state.lots[action.owner][action.id][action.hash].messages,
                                    [action.payload.id]: action.payload
                                }
                            },
                            newMessages: state.lots[action.owner][action.id].newMessages - action.thread.newMessages
                        }
                    }
                }
            };
        case FLUSH_ALL_MESSAGES:
            return { ...state, messages: INITIAL_STATE.messages };
        case FLUSH_ALL_THREADS:
            return { ...state, threads: INITIAL_STATE.threads };
        case FLUSH_ALL_LOTS:
            return { ...state, lots: INITIAL_STATE.lots };
        case CLEAR_MESSAGING_STATE:
            return INITIAL_STATE;
        case RESET_USER_STATE:
            return INITIAL_STATE;
        default:
            return state;
    }
}
