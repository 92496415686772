import { API_PREFIXES, PARTICIPANT_TYPE } from 'CONST';
import { FLUSH_ALL_MESSAGES, FLUSH_ALL_THREADS, GET_MESSAGING_LOT_LIST } from 'ACTIONS';
import { API_URLS } from 'CONFIG/API_URLS';
import { apiAction } from 'ACTIONS/api';
import { CLASSIFIEDS_PER_PAGE, MESSAGING_API, MESSAGING_CONF } from 'CONFIG';
import { checkNested } from 'MODULES/checkNested';
import { getUnixTimeStamp } from 'MODULES';
import { setIsFetching } from 'ACTIONS/ui/setIsFetching';


const apiPrefix = API_PREFIXES.msg;



export function getLotList(type = PARTICIPANT_TYPE.company, timestamp = null, callback) {
    return (dispatch) => {
        dispatch({ type: FLUSH_ALL_MESSAGES });
        dispatch({ type: FLUSH_ALL_THREADS });
        // dispatch({ type: FLUSH_ALL_LOTS });

        dispatch(setIsFetching(GET_MESSAGING_LOT_LIST, true));
        const timeStampParam = timestamp ? { from_time: parseFloat(timestamp) - 1 } : {};
        let url = API_URLS.THREADS;
        if (type === PARTICIPANT_TYPE.company) {
            url = API_URLS.THREADS_COMPANY;
        }
        dispatch(apiAction(
            {
                url,
                auth: true,
                host: MESSAGING_API,
                apiPrefix,
                data: {
                    ...timeStampParam,
                    limit: CLASSIFIEDS_PER_PAGE,
                    order: MESSAGING_CONF.messaging_sort,
                    pointer_direction: MESSAGING_CONF.messaging_sort
                },
                onSuccess: d => () => {
                    const response = checkNested(d, ['content'], {});
                    if (callback) {
                        callback();
                    }
                    if (response.totalCount && response.data.length > 0) {
                        let payload = {};
                        response.data.forEach((lot) => {
                            payload = {
                                ...payload,
                                [getUnixTimeStamp(lot.updatedAt)]: lot
                            };
                        });
                        dispatch({
                            type: GET_MESSAGING_LOT_LIST,
                            payload,
                            amount: response.totalCount,
                            owner: type === PARTICIPANT_TYPE.company ? 'company' : 'personal'
                        });
                    }
                },
                label: GET_MESSAGING_LOT_LIST
            }
        ));
    };
}
