import { createSelector } from 'reselect';
import { pathname } from 'MODULES/reselect/state';
import filter from 'lodash/filter';
import find from 'lodash/find';
import forEach from 'lodash/forEach';
import get from 'lodash/get';
import groupBy from 'lodash/groupBy';
import keyBy from 'lodash/keyBy';
import isEmpty from 'lodash/isEmpty';
import values from 'lodash/values';
import last from 'lodash/last';
import map from 'lodash/map';
import { formValueSelect } from 'MODULES/reselect/selectors/global';
import { FILTER_VISIBILITY, WINTER_BEATER_FILTER_ID } from 'MODULES/const';
import { getSelectedLanguage } from 'MODULES/reselect/selectors/general';
import { checkNested } from 'MODULES/checkNested';
import { FORMS } from 'CONFIG/FORMS';

const emptyObject = {};

export const getAllFilter = createSelector(
    [(state) => state.filters[pathname(state)]],
    (filters) => filters || emptyObject
);

export const getAllFilterByGroup = createSelector(
    [(state) => state.filters[pathname(state)]],
    (filters) => groupBy(checkNested(filters, 'categoryFilters.categoryFilters', [], true), 'filterSection.id') || {}
);

export const regionsFilters = createSelector(
    [getAllFilter],
    (filters) => filters.regions
);

export const haveWinterBeaterFilter = createSelector(
    [getAllFilter],
    (filters) => checkNested(keyBy(checkNested(filters, 'categoryFilters.categoryFilters'), 'filter.id'), WINTER_BEATER_FILTER_ID)
);


export const getFiltersRegionsByPath = createSelector(
    (state) => state.filters[pathname(state)],
    (regions) => checkNested(regions, 'regions')
);

export const regionsFiltersByIdState = createSelector(
    [getAllFilter],
    (filters) => {
        if (!filters.regions) {
            return emptyObject;
        }
        return keyBy([...checkNested(filters, 'regions.child', []),
            ...checkNested(filters, 'regions.parent', [])], 'id');
    }
);

export const regionsFiltersByTypeSelector = createSelector(
    [getAllFilter],
    (filters) => {
        if (!filters.regions) {
            return emptyObject;
        }
        return groupBy([...checkNested(filters, 'regions.child', []),
            ...checkNested(filters, 'regions.parent', [])], 'type');
    }
);



export const getFilters = createSelector(
    getAllFilter,
    (filters) => (checkNested(filters, 'categoryFilters.categoryFilters', [], true))
);

export const getTopFilter = createSelector(
    getAllFilter,
    (filters) => (filters.categoryFilters ? filter(filters.categoryFilters.categoryFilters, (o) => o.visibilityType === FILTER_VISIBILITY.top) : [])
);

export const getNotTopFilter = createSelector(
    getAllFilter,
    (filters) => (filters.categoryFilters ? filter(filters.categoryFilters.categoryFilters, (o) => o.visibilityType === FILTER_VISIBILITY.showMore) : [])
);

export const getPriceTypes = createSelector(
    getAllFilter,
    // hardcoded must change when actions will be ready
    (filters) => checkNested(filters, ['categoryFilters', 'priceRanges'], [])
);

export const getCombinedPriceRange = createSelector(
    [getAllFilter, formValueSelect(`${FORMS.filtersubscription.name}-${window.location.pathname}`, 'action')],
    // hardcoded must change when actions will be ready
    (filters, currentActionId) => {
        if (currentActionId) {
            const currentAction = find(checkNested(filters, ['categoryFilters', 'actions']), (a) => a.id === parseFloat(currentActionId));
            if (currentAction) {
                return currentAction.combinedPriceRange;
            }
        }
        return checkNested(filters, ['categoryFilters', 'combinedPriceRange'], []);
    }
);

export const getCurrentPriceTypes = createSelector(
    [getPriceTypes, getAllFilter, formValueSelect(`${FORMS.filtersubscription.name}-${window.location.pathname}`, 'action')],
    (priceTypes, filters, currentAction) => {
        const allActions = checkNested(filters, ['categoryFilters', 'actions']);
        if (allActions && currentAction) {
            const action = find(allActions, (a) => a.id === currentAction);
            return (action && action.priceTypes) || [];
        }
        return priceTypes;
    }
);

export const getCurrentPriceType = createSelector(
    [getCurrentPriceTypes, formValueSelect(`${FORMS.filtersubscription.name}-${window.location.pathname}`, 'priceType')],
    (priceTypes, currentType) => {
        let currentPriceRange;
        if (priceTypes.length && currentType) {
            currentPriceRange = find(priceTypes, (a) => a.id === parseFloat(currentType));
        }
        return currentPriceRange;
    }
);

export const getCategoryAction = createSelector(
    getAllFilter,
    // hardcoded must change when actions will be ready
    (actions) => checkNested(actions, 'categoryFilters.actions', [])
);

export const getCategoryActionById = createSelector(
    getAllFilter,
    // hardcoded must change when actions will be ready
    (actions) => keyBy(checkNested(actions, 'categoryFilters.actions', []), 'id')
);

export const sortSelector = createSelector(
    [getAllFilter],
    (filters) => get(filters, 'sortBy', '')
);

export const getCategoryTreeSelector = createSelector(
    [getAllFilter],
    (filters) => get(filters, 'categoryTree', {})
);

export const getSelectedCategoryFilter = createSelector(
    [getAllFilter],
    (filters) => get(filters, 'filerByCategory', false)
);

export const getFilterLotsCount = createSelector(
    [getAllFilter],
    (filters) => get(filters, 'filterLotsCount', 0)
);

export const getMultipleCategoryString = createSelector(
    [getAllFilter],
    (filters) => {
        let categories = '';
        forEach(filters.filerByCategory, (cat) => {
            if (cat[3] || cat[2] || cat[1]) {
                categories += `${checkNested(cat, '3', checkNested(cat, '2', checkNested(cat, '1')))},`;
            }
        });
        if (!categories) {
            return undefined;
        }
        categories = categories.slice(0, -1);
        return categories;
    }
);

export const getMultipleCategory = createSelector(
    [getAllFilter],
    (filters) => map(filters.filerByCategory, (f) => last(values(f))).join(',')
);

export const getMultipleCategoryTitleArray = createSelector(
    [getAllFilter, getSelectedLanguage],
    (filters, lang) => {
        const categories = [];
        forEach(filters.filerByCategory, (cat) => {
            if (cat[1] && !isEmpty(filters, 'categoryTree')) {
                const parentCategory = filters.categoryTree[`id_${cat[1]}`];
                let childrenCategory = '';
                if (cat[2] > 0) {
                    const allChildrens = keyBy(filters.categoryTree[`childrens_${cat[1]}`], 'id');
                    childrenCategory = ` ${checkNested(allChildrens, [cat[2], 'name', lang], checkNested(allChildrens, [`id-${cat[2]}`, 'name', lang], ''))}`;
                    if (cat[3] && allChildrens[cat[2]]) {
                        const allChildrensLevel2 = keyBy(allChildrens[cat[2]].children, 'id');
                        const parentData = allChildrensLevel2[cat[3]];
                        if (parentData) {
                            childrenCategory += ` ${parentData.name[lang]}`;
                        }
                    }
                }
                if (checkNested(parentCategory, 'name', lang)) {
                    categories.push(`${checkNested(parentCategory, ['name', lang])}${childrenCategory}`);
                }
            }
        });
        return categories;
    }
);

export const getFirstSelectedCategory = createSelector(
    [getAllFilter],
    (filters) => {
        let category = [];
        const categoryTree = get(filters, 'categoryTree', {});
        forEach(filters.filerByCategory, (cat) => {
            // eslint-disable-next-line prefer-destructuring
            category = filter(cat, (id) => checkNested(categoryTree, `id_${id}`))[0];
        });
        return category;
    }
);
