import { GET_NEW_MESSAGES_COUNT } from 'ACTIONS';
import { API_TYPE } from 'CONST';
import { apiAction } from 'ACTIONS/api';
import { API_URLS } from 'CONFIG/API_URLS';
import { MESSAGING_API } from 'CONFIG';
import { prepareSimplePayload } from 'MODULES/api';
import { apiPrefix } from 'ACTIONS/messaging/index';
import now from 'lodash/now';

export const getNewMessages = () => (dispatch, getState) => {
    const state = getState();
    const isFetching = state.ui.isFetching[GET_NEW_MESSAGES_COUNT];
    const offline = state.general.api_offline[API_TYPE.msg];
    if (offline.length > 0 && offline[offline.length - 1] >= now() || process.env.NODE_ENV === 'development') {
        return null;
    }
    if (!isFetching) {
        dispatch(
            apiAction(
                {
                    url: API_URLS.NEW_MESSAGES,
                    auth: true,
                    host: MESSAGING_API,
                    apiPrefix,
                    onSuccess: data => () => {
                        const companyNewMessages = data.content.company || 0;
                        const userNewMessages = data.content.user || 0;
                        dispatch({
                            type: GET_NEW_MESSAGES_COUNT,
                            payload: prepareSimplePayload(companyNewMessages + userNewMessages),
                            company: companyNewMessages,
                            user: userNewMessages
                        });
                    },
                    label: GET_NEW_MESSAGES_COUNT
                }
            )
        );
    }

    return null;
};
