import React, { useState } from 'react';
import classNames from 'classnames';
import { AmountLoader } from 'COMMON/loader';
import { useDispatch, useSelector } from 'react-redux';
import { checkNested } from 'MODULES/checkNested';
import { PP_TYPE } from 'MODULES/PP_TYPE';
import { toggleBookmark } from 'ACTIONS/bookmarks/toggleBookmark';
import { getBoomarkedClasifiedsState } from 'MODULES/reselect/selectors/bookmarks';

const BookmarkButton = ({ pp, i18n, ppType, toggleRemoveBookmark, favoriteCount, lotsPath, activePage }) => {
    const [fetching, setIsFetching] = useState(false);
    const dispatch = useDispatch();
    const bookMarkedLots = useSelector(getBoomarkedClasifiedsState);
    const isBookmarked = checkNested(pp, 'isBookmarked');
    const isBookmarkedPP = checkNested(bookMarkedLots, [pp.id], isBookmarked);
    const onBookmarkToggled = () => {
        setIsFetching(false);
    };
    if (fetching) {
        return <div className="fav-wrap" style={{ bottom: '0.5rem', paddingRight: '0.25rem' }}>{AmountLoader}</div>;
    }
    return (
        <div
            className="fav-wrap"
            onClick={
                (e) => {
                    e.preventDefault();
                    setIsFetching(true);
                    if (isBookmarkedPP && ppType === PP_TYPE.bookmarks) {
                        toggleRemoveBookmark(onBookmarkToggled);
                    } else {
                        dispatch(toggleBookmark(pp, isBookmarkedPP, onBookmarkToggled, lotsPath, activePage));
                    }
                }
            }
        >
            <i
                title={(isBookmarkedPP) ? i18n.__('Remove from favorites') : i18n.__('Add to favorites')}
                className={classNames('pp-landing', {
                    'pp-heart bookmark-remove pt-1 pb-2': isBookmarkedPP,
                    'pp-heart-o bookmark-add pt-1 pb-2': !isBookmarkedPP,
                    'pe-2': favoriteCount < 1
                })}
            />
            {favoriteCount > 0 && <span className="pe-2" />}
        </div>
    );
};

export default BookmarkButton;
