import { apiAction } from 'ACTIONS/api';
import { API_URLS } from 'CONFIG/API_URLS';
import { MESSAGING_API } from 'CONFIG';
import { PATCHING_MESSAGE } from 'ACTIONS';
import { PARTICIPANT_TYPE } from 'CONST';
import { getNewMessages } from 'ACTIONS/messaging/getNewMessages';
import { apiPrefix } from 'ACTIONS/messaging/index';

export const markMessageAsRead = (hash, pp, ownerType, message, thread) => (dispatch) => {
    if (!message) {
        return null;
    }

    return (dispatch(apiAction(
        {
            url: API_URLS.MESSAGES_BY_HASH(message),
            method: 'PATCH',
            auth: true,
            host: MESSAGING_API,
            apiPrefix,
            onSuccess: data => () => {
                dispatch({
                    type: PATCHING_MESSAGE,
                    payload: data.content,
                    thread,
                    id: thread.updatedAt,
                    hash,
                    owner: ownerType === PARTICIPANT_TYPE.company ? 'company' : 'personal'
                });
                dispatch(getNewMessages());
            },
            label: PATCHING_MESSAGE
        }
    )));
};
