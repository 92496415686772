import { GET_MESSAGE_LOTS, PATCHING_MESSAGE } from 'ACTIONS';
import { apiAction } from 'ACTIONS/api';
import { API_URLS } from 'CONFIG/API_URLS';
import { API_ERROR_CODES } from 'CONST';
import { checkNested } from 'MODULES/checkNested';
import { drawFilterPreview } from 'MODULES/drawFilterPreview';
import { getPpMainDescription } from 'MODULES/pp';
import { returnThumbUrlFromResponse } from 'MODULES/returnThumbUrlFromResponse';
import { setIsFetching } from 'ACTIONS/ui/setIsFetching';

export function getThreadLot(id, i18n, owner, timestamp) {
    if (!id) {
        return null;
    }
    return (dispatch) => {
        dispatch(setIsFetching([GET_MESSAGE_LOTS, id], true));
        dispatch(apiAction(
            {
                url: API_URLS.LOTS_BY_ID(id),
                auth: true,
                onFailure: (e, status) => () => {
                    if (status === API_ERROR_CODES[403]) {
                        dispatch({
                            type: GET_MESSAGE_LOTS,
                            payload: {
                                blocked: true,
                                id
                            },
                            owner
                        });
                        dispatch(setIsFetching([GET_MESSAGE_LOTS, timestamp], false));
                    }
                },
                onSuccess: data => () => {
                    const response = checkNested(data, ['content'], {});
                    if (response.id) {
                        const d = {
                            id: response.id,
                            adState: response.adState,
                            price: checkNested(response, ['prices', 0, 'value'], 0),
                            filters: drawFilterPreview(checkNested(response, ['adFilterValues'], []), i18n, true).map(f => f.val).join(', '),
                            title: getPpMainDescription(response, 'lv'),
                            category: response.category,
                            categoryTitle: response.categoryTitle,
                            company: response.company,
                            owner: response.owner,
                            thumb: returnThumbUrlFromResponse(response.files),
                            type: checkNested(response.files, '[0].file.outputType', 'image')
                        };
                        dispatch({
                            type: GET_MESSAGE_LOTS,
                            payload: d,
                            owner,
                            id: timestamp
                        });
                    }
                    dispatch(setIsFetching([GET_MESSAGE_LOTS, timestamp], false));
                },
                label: PATCHING_MESSAGE
            }
        ));
    };
}
