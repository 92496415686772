import React from 'react';

import { PP_URLS } from 'CONFIG/PP_URLS';

const FollowOnSocial = ({ i18n }) => (
    <div className="d-flex social-follow">
        <a rel="noopener" href={PP_URLS.FACEBOOK} target="_blank" className="social-link" aria-label={i18n.__('Follow us on Facebook')}>
            <i className="pp-landing pp-social-facebook" />
        </a>
        <a rel="noopener" href={PP_URLS.INSTAGRAM} target="_blank" className="social-link" aria-label={i18n.__('Follow us on Instagram')}>
            <i className="pp-landing pp-social-instagram" />
        </a>
    </div>
);
export default FollowOnSocial;
