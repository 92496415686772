import { apiAction } from 'ACTIONS/api';
import { API_URLS } from 'CONFIG/API_URLS';
import { MESSAGING_API } from 'CONFIG';
import { GET_ALL_MESSAGES } from 'ACTIONS';
import { apiPrefix } from 'ACTIONS/messaging/index';

export const getAllMessages = () => dispatch => dispatch(
    apiAction(
        {
            url: API_URLS.MESSAGES_TOTAL,
            auth: true,
            host: MESSAGING_API,
            apiPrefix,
            onSuccess: data => () => {
                dispatch({
                    type: GET_ALL_MESSAGES,
                    payload: { company: data.content.company, personal: data.content.personal }
                });
            },
            label: GET_ALL_MESSAGES
        }
    )
);
