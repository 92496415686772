import React, { forwardRef } from 'react';
import times from 'lodash/times';
import map from 'lodash/map';
import Skeleton from 'react-loading-skeleton';

const SkeletonListLoader = forwardRef((props, ref) => map(times(props.count), c => (
    <div
        ref={ref}
        key={c}
        style={{ zIndex: '5', padding: '0.25rem' }}
        className=" classified masonry-classified masonry-block-width"
    >
        <div className="pp-masonry">
            <Skeleton style={{ height: '9rem' }} />
        </div>
    </div>
)));


export default SkeletonListLoader;
